export const CALCULATE = "CALCULATE";
export const dollars = "Dollars";
export const gallons = "Gallons";
export const therms = "Therms";
export const kwh = "kWh";
export const thouCubicFt = "Thousand Cubic Feet";

export const diet_heavy_meat_eater = "Heavy Meat Eater"
export const diet_medium_meat_eater = "Medium Meat Eater"
export const diet_light_meat_eater = "Light Meat Eater"
export const diet_pescatarian = "Pescatarian"
export const diet_vegetarian = "Vegetarian"
export const diet_vegan = "Vegan"

export const misc_category_Pharma = "Pharma"
export const misc_category_Textile = "Textile"
export const misc_category_Paper = "Paper"
export const misc_category_Technology = "Technology"
export const misc_category_Vehicles = "Vehicles"
export const misc_category_Furniture = "Furniture"
export const misc_category_Hospitality = "Hospitality"
export const misc_category_Telephone = "Telephone"
export const misc_category_Banking = "Banking"
export const misc_category_Entertainment = "Entertainment"

export const yes = true;
export const no = false;
export const defaultUsage = 0;
export const constants_dict = {
  AC_electricity_percent: 0.14,
  Average_FO_CO2_emissions: 12460.0,
  Average_elec_CO2_emissions: 14019.997724,
  BTU_per_1000cf_NG: 1023000.0,
  BTU_per_gallon_FO: 138691.09,
  BTU_per_gallon_propane: 91335.94,
  BTU_per_kWh: 3412.0,
  CO2_C_ratio: 3.67,
  EF_fuel_oil_MMBtu: 163.05,
  EF_fuel_oil_gallon: 22.61,
  EF_natural_gas: 119.58,
  EF_natural_gas_therm: 11.68890913124,
  EF_passenger_vehicle: 19.6,
  EF_propane: 12.43,
  ENERGYSTAR_fridge_kWh: 488.0,
  HHV_fuel_oil: 138691.09,
  HHV_natural_gas: 1023000.0,
  HHV_propane: 91335.94,
  HH_size: 2.57,
  NG_CO2_annual_emissions: 7892.0,
  Natural_gas_cost_1000CF: 10.68,
  Natural_gas_cost_therm: 1.04,
  OilFuelRate: 0.0,
  average_mpg: 21.6,
  average_waste_emissions: 692.0,
  boiler_replacement_cost_savings: 78.34,
  boiler_replacement_savings_FO: 1056.0,
  boiler_replacement_savings_NG: 728.0,
  computer_energy_monitor_off: 66.5,
  computer_energy_off: 143.0,
  computer_energy_sleep_monitor_off: 31.7,
  computer_energy_sleep_off: 70.7,
  computer_sleep_savings: 107.1,
  conventional_fridge_kWh: 810.0,
  conversion_1000cf_to_therm: 10.23,
  conversion_QBtu_to_Btu: 1000000000000000.0,
  conversion_Tg_to_lb: 2204622620.0,
  cost_per_kWh: 0.1188,
  cost_per_mile: 0.1964,
  dryer_energy: 769.0,
  fridge_replacement_kWh_savings: 322.0,
  fuel_oil_cost: 4.02,
  gas_cost_gallon: 3.68,
  glass_recycling_avoided_emissions: -25.39,
  green_power_premium: 0.02,
  heating_percent_NG: 0.63,
  heating_percent_electricity: 0.09,
  heating_percent_fuel_oil: 0.87,
  heating_percent_propane: 0.7,
  kWh_per_load_laundry: 0.96,
  lamp_cost_savings: 4.0,
  lamp_kWh_savings: 33.0,
  mag_recycling_avoided_emissions: -27.46,
  metal_recycling_avoided_emissions: -89.38,
  monthly_FO_consumption: 46.0,
  monthly_NG_Consumption: 5500.0,
  monthly_elec_consumption: 943.0,
  monthly_propane_consumption: 39.0,
  newspaper_recycling_avoided_emissions: -113.14,
  nonCO2_vehicle_emissions_ratio: 1.01,
  plastic_recycling_avoided_emissions: -35.56,
  propane_cost: 2.47,
  thermostat_cooling_savings: 0.06,
  thermostat_heating_savings: 0.03,
  vehicle_efficiency_improvements: 0.04,
  window_replacement_cost_savings: 150.0,
  window_replacement_energy_savings: 25210000.0,
  short_flight_factor: 0.560583398,
  medium_flight_factor: 0.462658703,
  long_flight_factor:0.374668398,
  short_flight_mileage: 450,
  medium_flight_mileage: 1200,
  long_flight_mileage: 2000,
  diet_heavy_meat_eater: 0.62,
  diet_medium_meat_eater: 0.49,
  diet_light_meat_eater: 0.41,
  diet_pescatarian: 0.34,
  diet_vegetarian: 0.33,
  diet_vegan: 0.25,
  misc_category_Pharma: 1.83,
  misc_category_Textile: 0.28,
  misc_category_Paper: 0.19,
  misc_category_Technology: 0.81,
  misc_category_Vehicles: 0.21,
  misc_category_Furniture: 0.22,
  misc_category_Hospitality: 0.26,
  misc_category_Telephone: 0.17,
  misc_category_Banking: 0.28,
  misc_category_Entertainment: 0.22,
};

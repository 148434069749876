import React, { useState, useEffect } from "react";
import { Form, Col, Button } from "react-bootstrap";
//import { OverlayTrigger } from "react-bootstrap";
//Above line is meant to temporarily suppress compilation warning.
//When OverlayTrigger is needed, insert into statement on line 2.
import ToggleDisplayed from "../ToggleDisplayed";
import Overlay from "../Overlay";
import {
  dollars,
  thouCubicFt,
  therms,
  gallons,
  kwh,
  defaultUsage,
} from "../../calculations/calculationConstants";
import {
  calculatePropaneFootprint,
  calculateElectricityFootprint,
  calculateNaturalGasFootprint,
  calculateFuelOilFootprint,
} from "../../calculations/calculations";
function HomeEnergyForm({ homeEnergyFootprint, setHomeEnergyFootprint }) {
  const [propaneUnits, setPropaneUnits] = useState(dollars);
  const [propaneUsage, setPropaneUsage] = useState(defaultUsage);
  const [electricityUnits, setElectricityUnits] = useState(dollars);
  const [electricityUsage, setElectricityUsage] = useState(defaultUsage);
  const [greenEnergyPercentage, setGreenEnergyPercentage] = useState(
    defaultUsage
  );
  const [naturalGasUnits, setNaturalGasUnits] = useState(dollars);
  const [naturalGasUsage, setNaturalGasUsage] = useState(defaultUsage);
  const [fuelOilUnits, setFuelOilUnits] = useState(dollars);
  const [fuelOilUsage, setFuelOilUsage] = useState(defaultUsage);

  const [propaneContribution, setPropaneContribution] = useState(0);
  const [electricityContribution, setElectricityContribution] = useState(0);
  const [naturalGasContribution, setNaturalGasContribution] = useState(0);
  const [fuelOilContribution, setFuelOilContribution] = useState(0);

  useEffect(() => {
    setHomeEnergyFootprint(
      propaneContribution +
        electricityContribution +
        naturalGasContribution +
        fuelOilContribution
    );
  });

  return (
    <Form>
      <br></br>

      <Form.Group>
        <ToggleDisplayed switchId="electricityUse" condition="See Electricity">
          <Form.Row>
            <Form.Group as={Col} controlId="electricityFormGridUnits">
              <Form.Label>Units</Form.Label>
              <Form.Control
                as="select"
                placeholder={electricityUnits}
                onChange={(e) => {
                  setElectricityUnits(e.target.value);
                  setElectricityContribution(
                    calculateElectricityFootprint(
                      e.target.value,
                      electricityUsage,
                      greenEnergyPercentage
                    )
                  );
                }}
              >
                <option>{dollars}</option>
                <option>{kwh}</option>
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} controlId="electricityformGridUsage">
              <Form.Label>Monthly Usage</Form.Label>
              <Form.Control
                type="number"
                min={0}
                placeholder={electricityUsage}
                onChange={(e) => {
                  setElectricityUsage(e.target.value);
                  setElectricityContribution(
                    calculateElectricityFootprint(
                      electricityUnits,
                      e.target.value,
                      greenEnergyPercentage
                    )
                  );
                }}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="greenEnergyFormGridPercentage">
              <Form.Label>
                What % of your electricity comes from Green Energy?
                <Overlay
                  prompt="ⓘ"
                  title="Green Energy"
                  message="Green Energy is energy that comes from renewable resources."
                ></Overlay>
              </Form.Label>
              <Form.Control
                type="number"
                min={0}
                placeholder={greenEnergyPercentage}
                onChange={(e) => {
                  setGreenEnergyPercentage(e.target.value);
                  setElectricityContribution(
                    calculateElectricityFootprint(
                      electricityUnits,
                      electricityUsage,
                      e.target.value
                    )
                  );
                }}
              />
            </Form.Group>
          </Form.Row>
          {electricityContribution > 0 && (
            <center>
              <strong>
                Electricity Contribution: {electricityContribution} pounds
              </strong>
            </center>
          )}
        </ToggleDisplayed>

        <ToggleDisplayed switchId="naturalGasUse" condition="See Natural Gas">
          <Form.Row>
            <Form.Group as={Col} controlId="naturalGasFormGridUnits">
              <Form.Label>Units</Form.Label>
              <Form.Control
                as="select"
                placeholder={naturalGasUnits}
                onChange={(e) => {
                  setNaturalGasUnits(e.target.value);
                  setNaturalGasContribution(
                    calculateNaturalGasFootprint(
                      naturalGasUnits,
                      e.target.value
                    )
                  );
                }}
              >
                <option>{dollars}</option>
                <option>{thouCubicFt}</option>
                <option>{therms}</option>
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} controlId="naturalGasformGridUsage">
              <Form.Label>Monthly Usage</Form.Label>
              <Form.Control
                type="number"
                min={0}
                placeholder={naturalGasUsage}
                onChange={(e) => {
                  setNaturalGasUsage(e.target.value);
                  setNaturalGasContribution(
                    calculateNaturalGasFootprint(
                      naturalGasUnits,
                      e.target.value
                    )
                  );
                }}
              />
            </Form.Group>
          </Form.Row>
          {naturalGasContribution > 0 && (
            <center>
              <strong>
                Natural Gas Contribution: {naturalGasContribution} pounds
              </strong>
            </center>
          )}
        </ToggleDisplayed>
        <ToggleDisplayed
          switchId="propaneUse"
          condition="See Propane"
          onChange={(e) => setPropaneUsage(0)}
        >
          <Form.Row>
            <Form.Group as={Col} controlId="propaneFormGridUnits">
              <Form.Label>Units</Form.Label>
              <Form.Control
                as="select"
                placeholder={propaneUnits}
                onChange={(e) => {
                  setPropaneUnits(e.target.value);
                  let p = calculatePropaneFootprint(
                    e.target.value,
                    propaneUsage
                  );
                  setPropaneContribution(p);
                }}
              >
                <option>Dollars</option>
                <option>Gallons</option>
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} controlId="propaneformGridUsage">
              <Form.Label>Monthly Usage</Form.Label>
              <Form.Control
                type="number"
                min={0}
                placeholder={propaneUsage}
                onChange={(e) => {
                  setPropaneUsage(e.target.value);
                  setPropaneContribution(
                    calculatePropaneFootprint(propaneUnits, e.target.value)
                  );
                }}
              />
            </Form.Group>
          </Form.Row>
          {propaneContribution > 0 && (
            <center>
              <strong>
                Propane Contribution: {propaneContribution} pounds
              </strong>
            </center>
          )}
        </ToggleDisplayed>
        <ToggleDisplayed switchId="fuelOilUse" condition="See Fuel Oil ">
          <Overlay
            prompt="ⓘ"
            title="What is 'fuel oil'?"
            message="Fuel oil, a.k.a. 'heating oil', is used in boilers and furnaces for heating homes and buildings"
          ></Overlay>
          <Form.Row>
            <Form.Group as={Col} controlId="fuelOilFormGridUnits">
              <Form.Label>Units</Form.Label>
              <Form.Control
                as="select"
                placeholder={fuelOilUnits}
                onChange={(e) => {
                  setFuelOilUnits(e.target.value);
                  setFuelOilContribution(
                    calculateFuelOilFootprint(e.target.value, fuelOilUsage)
                  );
                }}
              >
                <option>{dollars}</option>
                <option>{gallons}</option>
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} controlId="fuelOilformGridUsage">
              <Form.Label>Monthly Usage</Form.Label>
              <Form.Control
                type="number"
                min={0}
                placeholder={fuelOilUsage}
                onChange={(e) => {
                  setFuelOilUsage(e.target.value);
                  setFuelOilContribution(
                    calculateFuelOilFootprint(fuelOilUnits, e.target.value)
                  );
                }}
              />
            </Form.Group>
          </Form.Row>
          {fuelOilContribution > 0 && (
            <center>
              <strong>
                Fuel Oil Contribution: {fuelOilContribution} pounds
              </strong>
            </center>
          )}
        </ToggleDisplayed>
      </Form.Group>
      {homeEnergyFootprint > 0 && (
        <Button variant="primary" size="lg" block>
          <h4>
            {" "}
            Annual Home Energy Footprint: <strong>
              {homeEnergyFootprint}
            </strong>{" "}
            pounds CO
            <sub>2</sub>
          </h4>
        </Button>
      )}
    </Form>
  );
}

export default HomeEnergyForm;

import {
  constants_dict,
  dollars,
  thouCubicFt,
  therms,
  gallons,
  diet_heavy_meat_eater,
  diet_medium_meat_eater,
  diet_light_meat_eater,
  diet_pescatarian,
  diet_vegetarian,
  diet_vegan,
  misc_category_Pharma,
  misc_category_Textile,
  misc_category_Paper,
  misc_category_Technology,
  misc_category_Vehicles,
  misc_category_Furniture,
  misc_category_Hospitality,
  misc_category_Telephone,
  misc_category_Banking,
  misc_category_Entertainment,
} from "./calculationConstants";

export function calculatePropaneFootprint(propaneUnits, propaneUsage) {
  propaneUsage = parseFloat(propaneUsage);
  let prefix = 0;
  if (propaneUnits === dollars) {
    prefix = 1 / constants_dict.propane_cost;
  } else if (propaneUnits === gallons) {
    prefix = 1;
  }
  return Math.round(prefix * (propaneUsage * constants_dict.EF_propane * 12));
}

export function calculateNaturalGasFootprint(naturalGasUnits, naturalGasUsage) {
  naturalGasUsage = parseFloat(naturalGasUsage);
  let prefix = 0;
  if (naturalGasUnits === dollars) {
    prefix =
      (1 / constants_dict.Natural_gas_cost_1000CF) *
      constants_dict.EF_natural_gas;
  } else if (naturalGasUnits === thouCubicFt) {
    prefix = constants_dict.EF_natural_gas;
  } else if (naturalGasUnits === therms) {
    prefix = constants_dict.EF_natural_gas_therm;
  }
  return Math.round(prefix * (naturalGasUsage * 12));
}

export function calculateFuelOilFootprint(fuelOilUnits, fuelOilUsage) {
  fuelOilUsage = parseFloat(fuelOilUsage);
  let prefix = 0;
  if (fuelOilUnits === dollars) {
    prefix = 1 / constants_dict.fuel_oil_cost;
  } else if (fuelOilUnits === gallons) {
    prefix = 1;
  }
  return Math.round(
    prefix * (fuelOilUsage * constants_dict.EF_fuel_oil_gallon * 12)
  );
}

export function calculateElectricityFootprint(
  electricityUnits,
  electricityUsage,
  greenEnergyPercentage
) {
  // todo: figure out the right ef value
  let EF_electricity = 1;
  electricityUsage = parseFloat(electricityUsage);
  greenEnergyPercentage = parseFloat(greenEnergyPercentage) / 100;
  let prefix = 0;
  if (electricityUnits === dollars) {
    prefix = 1 / constants_dict.cost_per_kWh;
  } else if (electricityUnits) {
    prefix = 1;
  }
  return greenEnergyPercentage > 0
    ? Math.round(
        prefix *
          (electricityUsage * EF_electricity * 12) *
          (1 - greenEnergyPercentage)
      )
    : Math.round(prefix * (electricityUsage * EF_electricity * 12));
}

export function calculateRecycleGlassFootprint(recycling, numPeople) {
  if (recycling) {
    return Math.round(
      numPeople * constants_dict.glass_recycling_avoided_emissions
    );
  } else {
    return 0;
  }
}
export function calculateRecycleMetalFootprint(recycling, numPeople) {
  if (recycling) {
    return Math.round(
      numPeople * constants_dict.metal_recycling_avoided_emissions
    );
  } else {
    return 0;
  }
}
export function calculateRecyclePlasticFootprint(recycling, numPeople) {
  if (recycling) {
    return Math.round(
      numPeople * constants_dict.plastic_recycling_avoided_emissions
    );
  } else {
    return 0;
  }
}
export function calculateRecyclePaperFootprint(recycling, numPeople) {
  if (recycling) {
    return Math.round(
      numPeople * constants_dict.newspaper_recycling_avoided_emissions
    );
  } else {
    return 0;
  }
}

export function calculateFlightsFootprint(numberOfFlights, length) {
  var emissions_factor;
  var mileage;
  switch(length){
    case "short":
      emissions_factor = constants_dict.short_flight_factor;
      mileage = constants_dict.short_flight_mileage;
      break;
    case "medium":
      emissions_factor = constants_dict.medium_flight_factor;
      mileage = constants_dict.medium_flight_mileage;
      break;
    case "long":
      emissions_factor = constants_dict.long_flight_factor;
      mileage = constants_dict.medium_flight_mileage;
      break;
    default:
      break;
  }
  return Math.round(mileage * emissions_factor * numberOfFlights);
}

export function calculateFoodFootprint(diet, annualSpend) {
  var emissions_factor;
  switch(diet){
    case diet_heavy_meat_eater:
      emissions_factor = constants_dict.diet_heavy_meat_eater;
      break;
    case diet_medium_meat_eater:
      emissions_factor = constants_dict.diet_medium_meat_eater;
      break;
    case diet_light_meat_eater:
      emissions_factor = constants_dict.diet_light_meat_eater;
      break;
    case diet_pescatarian:
      emissions_factor = constants_dict.diet_pescatarian;
      break;
    case diet_vegetarian:
      emissions_factor = constants_dict.diet_vegetarian;
      break;
    case diet_vegan:
      emissions_factor = constants_dict.diet_vegan;
      break;
    default:
      break;
  }
  return Math.round((annualSpend/1000.0) * emissions_factor * 2204);
}

export function calculateCarFootprint(mpg, weeklyMileage) {
  return Math.round(
    ((weeklyMileage * 52) / mpg) *
      constants_dict.EF_passenger_vehicle *
      constants_dict.nonCO2_vehicle_emissions_ratio
  );
}

export function calculateMiscFootprint(misc_category, annualSpend){
  var emissions_factor;
  switch(misc_category){
    case misc_category_Pharma:
      emissions_factor = constants_dict.misc_category_Pharma;
      break;
    case misc_category_Textile:
      emissions_factor = constants_dict.misc_category_Textile;
      break;
    case misc_category_Paper:
      emissions_factor = constants_dict.misc_category_Paper;
      break;
    case misc_category_Technology:
      emissions_factor = constants_dict.misc_category_Technology;
      break;
    case misc_category_Vehicles:
      emissions_factor = constants_dict.misc_category_Vehicles;
      break;
    case misc_category_Furniture:
      emissions_factor = constants_dict.misc_category_Furniture;
      break;
    case misc_category_Hospitality:
      emissions_factor = constants_dict.misc_category_Hospitality;
      break;
    case misc_category_Telephone:
      emissions_factor = constants_dict.misc_category_Telephone;
      break;
    case misc_category_Banking:
      emissions_factor = constants_dict.misc_category_Banking;
      break;
    case misc_category_Entertainment:
      emissions_factor = constants_dict.misc_category_Entertainment;
      break;
    default:
      break;
  }
  return Math.round((annualSpend/1000.0) * emissions_factor * 2204);
}


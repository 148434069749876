import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";

function FootprintBar({
  homeEnergyFootprint,
  transportationFootprint,
  materialsUseFootprint,
  recycleFootprint,
  foodFootprint,
  servicesFootprint,
}) {
  const [graphState, setGraphState] = useState({
    labels: ["Home Energy", "Transportation", "Material Use","Recycling", "Food", "Services"],
    datasets: [
      {
        label: "Pounds of Carbon Dioxide",
        backgroundColor: "rgba(75,192,192,1)",
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 2,
        data: [homeEnergyFootprint, transportationFootprint, materialsUseFootprint, recycleFootprint, foodFootprint, servicesFootprint],
      },
    ],
  });
  useEffect(() => {
    setGraphState({
      labels: ["Home Energy", "Transportation", "Material Use", "Recycling", "Food", "Services"],
      datasets: [
        {
          label: "Pounds of Carbon Dioxide",
          backgroundColor: "rgba(75,192,192,1)",
          borderColor: "rgba(0,0,0,1)",
          borderWidth: 2,
          data: [
            homeEnergyFootprint,
            transportationFootprint,
            materialsUseFootprint,
            recycleFootprint,
            foodFootprint,
            servicesFootprint
          ],
        },
      ],
    });
  }, [homeEnergyFootprint, transportationFootprint, materialsUseFootprint, recycleFootprint, foodFootprint, servicesFootprint]);

  return (
    <div>
      <Bar
        data={graphState}
        options={{
          title: {
            display: true,
            text: "Annual Footprint Breakdown",
            fontSize: 20,
          },
          legend: {
            display: true,
            position: "right",
          },
        }}
      />
      </div>
  );
}

export default FootprintBar;

import React, { useState, useEffect } from "react";
import { Form, Col } from "react-bootstrap";
function DemographicForm({ demographicInfo, setDemographicInfo }) {
  const [numberOfPeople, setNumPeople] = useState(demographicInfo.numPeople);
  const [zipCode, setZip] = useState(demographicInfo.zip);
  useEffect(() => {
    if (
      demographicInfo.numPeople !== numberOfPeople ||
      demographicInfo.zip !== zipCode
    ) {
      setDemographicInfo({ numPeople: numberOfPeople, zip: zipCode });
    }
  });

  return (
    <Form>
      <br></br>
      <Form.Row>
        <Form.Group as={Col} controlId="numberPeople">
          <Form.Label>Number of people in your household</Form.Label>
          <Form.Control
            type="number"
            min={1}
            placeholder={demographicInfo.numPeople}
            onChange={(e) => {
              setNumPeople(e.target.value);
            }}
          />
        </Form.Group>
        <Form.Group as={Col} controlId="zipCode">
          <Form.Label>Zip Code</Form.Label>
          <Form.Control
            type="number"
            placeholder={demographicInfo.zip}
            onChange={(e) => {
              setZip(e.target.value);
            }}
          ></Form.Control>
        </Form.Group>
      </Form.Row>
    </Form>
  );
}

export default DemographicForm;

import { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import Footer from "./components/Footer";
import VerticalTabs from "./components/VerticalTabs";


function App() {
  const [totalFootprint, setTotalFootprint] = useState(0);
  const [demographicInfo, setDemographicInfo] = useState({
    numPeople: 1,
    zip: 17603,
  });

  const [homeEnergyFootprint, setHomeEnergyFootprint] = useState(0);

  const [transportationFootprint, setTransportationFootprint] = useState(0);
  const [foodFootprint, setFoodFootprint] = useState(0);
  const [servicesFootprint, setServicesFootprint] = useState(0);
  const [recycleFootprint, setRecycleFootprint] = useState(0);
  const [materialsUseFootprint, setMaterialsUseFootprint] = useState(0);

  const [monthlyOffsetCost, setMonthlyOffsetCost] = useState(1);
  useEffect(() => {
    // we are charging $75 / ton.   75/2000 = .0375; this is the price per pound.
    setMonthlyOffsetCost(
      Math.max(1, Math.round((totalFootprint / 12) * 0.0375))
    );
  }, [totalFootprint]);

  useEffect(() => {
    setTotalFootprint(
      homeEnergyFootprint + materialsUseFootprint + recycleFootprint + transportationFootprint  + foodFootprint + servicesFootprint
    );
  }, [homeEnergyFootprint, materialsUseFootprint, recycleFootprint, transportationFootprint, foodFootprint, servicesFootprint]);
  return (
    <div>
      <main className="py-3">
        <Container>
          <VerticalTabs
            monthlyOffsetCost={monthlyOffsetCost}
            totalFootprint={totalFootprint}
            demographicInfo={demographicInfo}
            homeEnergyFootprint={homeEnergyFootprint}
            setHomeEnergyFootprint={setHomeEnergyFootprint}
            transportationFootprint={transportationFootprint}
            setTransportationFootprint={setTransportationFootprint}
            setDemographicInfo={setDemographicInfo}
            materialsUseFootprint={materialsUseFootprint}
            setMaterialsUseFootprint={setMaterialsUseFootprint}
            recycleFootprint={recycleFootprint}
            setRecycleFootprint={setRecycleFootprint}
            foodFootprint={foodFootprint}
            setFoodFootprint={setFoodFootprint}
            servicesFootprint={servicesFootprint}
            setServicesFootprint={setServicesFootprint}
          />
        </Container>
      </main>
      <Footer />
    </div>
  );
}

export default App;

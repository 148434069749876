import React, { useState } from "react";
import { Tab, Row, Col, Nav, Button } from "react-bootstrap";

import DemographicForm from "./forms/DemographicForm";
import HomeEnergyForm from "./forms/HomeEnergyForm";
import TransportationForm from "./forms/TransportationForm";
import FoodForm from "./forms/FoodForm"
import MaterialsForm from "./forms/MaterialsForm";
import ServicesForm from "./forms/ServicesForm"
import CheckoutForm from "./forms/CheckoutForm";
import FootprintBar from "./forms/FootprintBar";

import PrevNextButton from "./PrevNextButton";
import "../verticalTabs.css";


function VerticalTabs({
  totalFootprint,
  homeEnergyFootprint,
  setHomeEnergyFootprint,
  demographicInfo,
  setDemographicInfo,
  transportationFootprint,
  setTransportationFootprint,
  materialsUseFootprint,
  setMaterialsUseFootprint,
  recycleFootprint,
  setRecycleFootprint,
  foodFootprint,
  setFoodFootprint,
  servicesFootprint,
  setServicesFootprint,
  monthlyOffsetCost
}) {
  const [tabKey, setTabKey] = useState("demographic");
  function handleTabSelect(key) {
    setTabKey(key);
  }
  return (
    <Tab.Container id="left-tabs-example" activeKey={tabKey}>
      <h1>  Carbon Footprint Calculator </h1>
      <Row>
        <Col sm={3}>
          <Nav variant="pills" className="flex-column" >
            <Nav.Item>
              <Nav.Link eventKey="demographic" onSelect={handleTabSelect}>
                <i class="fas fa-users"></i> Demographic
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="home-energy" onSelect={handleTabSelect}>
                <i class="fas fa-atom"></i> Home Energy
              </Nav.Link>
            </Nav.Item>{" "}
            <Nav.Item>
              <Nav.Link eventKey="transportation" onSelect={handleTabSelect}>
                <i class="fas fa-car"></i> Transportation
              </Nav.Link>
            </Nav.Item>{" "}
            <Nav.Item>
              <Nav.Link eventKey="food" onSelect={handleTabSelect}>
                <i class="fas fa-utensils"></i> Food
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="materials" onSelect={handleTabSelect}>
                <i class="fas fa-recycle"></i> Materials
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="services" onSelect={handleTabSelect}>
                <i class="fas fa-asterisk"></i> Services
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="annualFootprint" onSelect={handleTabSelect}>
                <i class="fas fa-calculator"></i> Annual CO<sub>2</sub>{" "}
                Footprint
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="purchase" onSelect={handleTabSelect}>
                <i class="fas fa-calculator"></i> Buy Offset
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col sm={9}>
          <Tab.Content>
            <Tab.Pane eventKey="demographic" >
              <DemographicForm
                demographicInfo={demographicInfo}
                setDemographicInfo={setDemographicInfo}
              />
              <PrevNextButton tabEventKey="demographic" tabChanger={handleTabSelect}/>
            </Tab.Pane>
            <Tab.Pane eventKey="home-energy">
              <HomeEnergyForm
                homeEnergyFootprint={homeEnergyFootprint}
                setHomeEnergyFootprint={setHomeEnergyFootprint}
              />
              <PrevNextButton tabEventKey="home-energy" tabChanger={handleTabSelect}/>
            </Tab.Pane>
            <Tab.Pane eventKey="transportation">
              <TransportationForm
                transportationFootprint={transportationFootprint}
                setTransportationFootprint={setTransportationFootprint}
              />
              <PrevNextButton tabEventKey="transportation" tabChanger={handleTabSelect}/>
            </Tab.Pane>
            <Tab.Pane eventKey="food">
              <FoodForm
                foodFootprint={foodFootprint}
                setFoodFootprint={setFoodFootprint}
              />
              <PrevNextButton tabEventKey="food" tabChanger={handleTabSelect}/>
            </Tab.Pane>
            <Tab.Pane eventKey="materials">
              <MaterialsForm
                materialsUseFootprint={materialsUseFootprint}
                setMaterialsUseFootprint={setMaterialsUseFootprint}
                recycleFootprint={recycleFootprint}
                setRecycleFootprint={setRecycleFootprint}
                demographicInfo={demographicInfo}
              />
              <PrevNextButton tabEventKey="materials" tabChanger={handleTabSelect}/>
            </Tab.Pane>
            <Tab.Pane eventKey="services">
              <ServicesForm
                servicesFootprint={servicesFootprint}
                setServicesFootprint={setServicesFootprint}
              />
              <PrevNextButton tabEventKey="services" tabChanger={handleTabSelect}/>
            </Tab.Pane>
            <Tab.Pane eventKey="annualFootprint">
              <br></br>
              {(totalFootprint > 0 && (
                <div>
                  <FootprintBar
                    homeEnergyFootprint={homeEnergyFootprint}
                    transportationFootprint={transportationFootprint}
                    materialsUseFootprint={materialsUseFootprint}
                    recycleFootprint={recycleFootprint}
                    foodFootprint={foodFootprint}
                    servicesFootprint={servicesFootprint}
                  />
                  <Button variant="primary" size="lg" block>
                    <h4>
                      {" "}
                      Total Annual Footprint: <strong>
                        {totalFootprint}
                      </strong>{" "}
                      pounds CO
                      <sub>2</sub>
                    </h4>
                  </Button>
                </div>
              )) || (
                <h4>
                  Please fill out the required information to see your annual CO
                  <sub>2</sub> footprint.
                </h4>
              )}
              <PrevNextButton tabEventKey="annualFootprint" tabChanger={handleTabSelect}/>
            </Tab.Pane>
            <Tab.Pane eventKey="purchase">
              <br></br>
              {(totalFootprint > 0 && (
                <div>

          <CheckoutForm 
            monthlyOffsetCost={monthlyOffsetCost}
            homeEnergyFootprint={homeEnergyFootprint}
            transportationFootprint={transportationFootprint}
            materialsUseFootprint={materialsUseFootprint}
            recycleFootprint={recycleFootprint}
            foodFootprint={foodFootprint}
            servicesFootprint={servicesFootprint}
            totalFootprint={totalFootprint}
           />
                </div>
              )) || (
                <h4>
                  Please fill out the questionnaire before purchasing an offset.
                </h4>
              )}
              <PrevNextButton tabEventKey="purchase" tabChanger={handleTabSelect}/>
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
}

export default VerticalTabs;

import React from "react";
import { Row, Col, Button, Container } from "react-bootstrap";

function PrevNextButton(props) {
  function viewChange() {
    var prev = null;
    var next = null;
    switch(props.tabEventKey) {
      case "demographic" :
        next = "home-energy";
        break;
      case "home-energy" :
        prev = "demographic";
        next = "transportation";
        break;
      case "transportation" :
        prev = "home-energy";
        next = "food";
        break;
      case "food" :
        prev = "transportation"
        next = "materials";
        break;
      case "materials" :
        prev = "food";
        next = "services"
        break;
      case "services" :
        prev = "materials";
        next = "annualFootprint"
        break;
      case "annualFootprint" :
        prev = "services";
        next = "purchase"
        break;
      case "purchase" :
        prev = "annualFootprint";
        break;
      default: 
    }
    return {prevDestination:prev, nextDestination:next}
  }
  return (
    <Container fluid>
      <Row className="mt-2 justify-content-sm-between">
        <Col sm="auto">
          <Button 
            onClick={() => props.tabChanger(viewChange().prevDestination)} 
            style={{visibility: (viewChange().prevDestination === null) ? 'hidden' : 'visible'}}
            >Previous
          </Button>
        </Col>
        <Col sm="auto">
          <Button 
            onClick={() => props.tabChanger(viewChange().nextDestination)} 
            style={{visibility: (viewChange().nextDestination === null) ? 'hidden' : 'visible'}}
            >Next
          </Button>
        </Col>
      </Row>
    </Container>
  )
}

export default PrevNextButton;
import React from "react";
import { OverlayTrigger, Button, Popover } from "react-bootstrap";
function Overlay({ prompt, title, message }) {
  const popover = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">{title}</Popover.Title>
      <Popover.Content>{message}</Popover.Content>
    </Popover>
  );
  return (
    <OverlayTrigger trigger="hover" placement="right" overlay={popover}>
      <Button variant="light">{prompt}</Button>
    </OverlayTrigger>
  );
}

export default Overlay;

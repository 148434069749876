import { Form, Col, Row, Button } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import {
  calculateMiscFootprint,
  calculateRecycleGlassFootprint,
  calculateRecycleMetalFootprint,
  calculateRecyclePlasticFootprint,
  calculateRecyclePaperFootprint,
} from "../../calculations/calculations";
import { 
  misc_category_Pharma,
  misc_category_Textile,
  misc_category_Paper,
  misc_category_Technology,
  misc_category_Vehicles,
  misc_category_Furniture,
} from "../../calculations/calculationConstants";


function MaterialsForm({
  materialsUseFootprint,
  setMaterialsUseFootprint,
  recycleFootprint,
  setRecycleFootprint,
  demographicInfo,
}) {
  const [PharmaSpend, setPharmaSpend] = useState(0);
  const [TextileSpend, setTextileSpend] = useState(0);
  const [PaperSpend, setPaperSpend] = useState(0);
  const [TechnologySpend, setTechnologySpend] = useState(0);
  const [VehiclesSpend, setVehiclesSpend] = useState(0);
  const [FurnitureSpend, setFurnitureSpend] = useState(0);

  const [PharmaContribution, setPharmaContribution] = useState(0);
  const [TextileContribution, setTextileContribution] = useState(0);
  const [PaperContribution, setPaperContribution] = useState(0);
  const [TechnologyContribution, setTechnologyContribution] = useState(0);
  const [VehiclesContribution, setVehiclesContribution] = useState(0);
  const [FurnitureContribution, setFurnitureContribution] = useState(0);

 
  useEffect(() => {
    setMaterialsUseFootprint(
      PharmaContribution +
      TextileContribution +
      PaperContribution +
      TechnologyContribution +
      VehiclesContribution +
      FurnitureContribution
    );
  }, [setMaterialsUseFootprint, PharmaContribution, TextileContribution, PaperContribution, TechnologyContribution, VehiclesContribution, FurnitureContribution]);


  const [recycleGlassContribution, setRecycleGlassContribution] = useState(0);
  const [recycleAluminumContribution, setRecycleAluminumContribution] = useState(0);
  const [recyclePlasticContribution, setPlasticContribution] = useState(0);
  const [recyclePaperContribution, setRecyclePaperContribution] = useState(0);

  const [recycleGlass, setRecycleGlass] = useState(false);
  const [recycleAluminum, setRecycleAluminum] = useState(false);
  const [recyclePlastic, setRecyclePlastic] = useState(false);
  const [recyclePaper, setRecyclePaper] = useState(false);

  useEffect(() => {
    setRecycleGlassContribution(
      calculateRecycleGlassFootprint(recycleGlass, demographicInfo.numPeople)
    );
  }, [recycleGlass, demographicInfo]);
  useEffect(() => {
    setRecycleAluminumContribution(
      calculateRecycleMetalFootprint(recycleAluminum, demographicInfo.numPeople)
    );
  }, [recycleAluminum, demographicInfo]);
  useEffect(() => {
    setPlasticContribution(
      calculateRecyclePlasticFootprint(recyclePlastic, demographicInfo.numPeople)
    );
  }, [recyclePlastic, demographicInfo]);
  useEffect(() => {
    setRecyclePaperContribution(
      calculateRecyclePaperFootprint(recyclePaper, demographicInfo.numPeople)
    );
  }, [recyclePaper, demographicInfo]);

  useEffect(() => {
    setRecycleFootprint(
      recycleGlassContribution +
        recycleAluminumContribution +
        recyclePlasticContribution +
        recyclePaperContribution
    );
  }, [
    recycleGlassContribution,
    recycleAluminumContribution,
    recyclePlasticContribution,
    recyclePaperContribution,
  ]);


  return (
    <Form>
            <br></br>
      <Form.Row>
        <Form.Group as={Col} controlId="PharmaSpend">
          <Form.Label>Annual dollar amount spent on Pharamaceutical Purchases</Form.Label>
          <Form.Control
            type="number"
            min={0}
            placeholder={PharmaSpend}
            onChange={(e) => {
              setPharmaSpend(e.target.value);
              setPharmaContribution( 
                calculateMiscFootprint(
                  misc_category_Pharma,
                  e.target.value
                )
              );
            }}
          />
        </Form.Group>
        <Form.Group as={Col} controlId="TextileSpend">
          <Form.Label>Annual dollar amount spent on Textile Purchases (clothes, shoes, etc)</Form.Label>
          <Form.Control
            type="number"
            min={0}
            placeholder={TextileSpend}
            onChange={(e) => {
              setTextileSpend(e.target.value);
              setTextileContribution(
                calculateMiscFootprint(
                  misc_category_Textile,
                  e.target.value
                )
              );
            }}
          />
        </Form.Group>
        <Form.Group as={Col} controlId="PaperSpend">
          <Form.Label>Annual dollar amount spent on Paper Purchases (e.g. books, magazines, newspapers)</Form.Label>
          <Form.Control
            type="number"
            min={0}
            placeholder={PaperSpend}
            onChange={(e) => {
              setPaperSpend(e.target.value);
              setPaperContribution( 
                calculateMiscFootprint(
                  misc_category_Paper,
                  e.target.value
                )
              );
            }}
          />
        </Form.Group>
        <Form.Group as={Col} controlId="TechnologySpend">
          <Form.Label>Annual dollar amount spent on Technology Purchases(e.g. computers, TVs, cell phones, stereo equipment etc.)</Form.Label>
          <Form.Control
            type="number"
            min={0}
            placeholder={TechnologySpend}
            onChange={(e) => {
              setTechnologySpend(e.target.value);
              setTechnologyContribution( 
                calculateMiscFootprint(
                  misc_category_Technology,
                  e.target.value
                )
              );
            }}
          />
        </Form.Group>
        <Form.Group as={Col} controlId="VehiclesSpend">
          <Form.Label>Annual dollar amount spent on Vehicles Purchases (car, truck, motorcycle, etc.  Excludes fuel)</Form.Label>
          <Form.Control
            type="number"
            min={0}
            placeholder={VehiclesSpend}
            onChange={(e) => {
              setVehiclesSpend(e.target.value);
              setVehiclesContribution( 
                calculateMiscFootprint(
                  misc_category_Vehicles,
                  e.target.value
                )
              );
            }}
          />
        </Form.Group>
        <Form.Group as={Col} controlId="FurnitureSpend">
          <Form.Label>Annual dollar amount spent on Furniture Purchases and Other Manufactured Goods</Form.Label>
          <Form.Control
            type="number"
            min={0}
            placeholder={FurnitureSpend}
            onChange={(e) => {
              setFurnitureSpend(e.target.value);
              setFurnitureContribution( 
                calculateMiscFootprint(
                  misc_category_Furniture,
                  e.target.value
                )
              );
            }}
          />
        </Form.Group>
        </Form.Row>
      {materialsUseFootprint > 0 && (
        <Button variant="primary" size="lg" block>
          <h4>
            {" "}
            Annual Materials Footprint: <strong>
              {materialsUseFootprint}
            </strong>{" "}
            pounds CO
            <sub>2</sub>
          </h4>
        </Button>
      )}
      <br></br>

      <Form.Group>
        <h5>
          {" "}
          <strong>Select any of the following materials that your household recycles:</strong>
        </h5>
        <Form.Group as={Row} controlId="glassFormHorizontalCheck">
          <Col sm={{ span: 10, offset: 0 }}>
            <Form.Check
              type="checkbox"
              label="Glass"
              onChange={(e) => setRecycleGlass(!recycleGlass)}
            />
          </Col>
        </Form.Group>
        {recycleGlassContribution !== 0 && (
          <center>
            <strong>
              Recycling Glass Contribution: {recycleGlassContribution} pounds
            </strong>
          </center>
        )}
        <Form.Group as={Row} controlId="aluminumFormHorizontalCheck">
          <Col sm={{ span: 10, offset: 0 }}>
            <Form.Check
              label="Aluminum"
              onChange={(e) => setRecycleAluminum(!recycleAluminum)}
            />
          </Col>
        </Form.Group>
        {recycleAluminumContribution !== 0 && (
          <center>
            <strong>
              Recycling Aluminum Contribution: {recycleAluminumContribution} pounds
            </strong>
          </center>
        )}
        <Form.Group as={Row} controlId="plasticFormHorizontalCheck">
          <Col sm={{ span: 10, offset: 0 }}>
            <Form.Check
              label="Plastic"
              onChange={(e) => setRecyclePlastic(!recyclePlastic)}
            />
          </Col>
        </Form.Group>
        {recyclePlasticContribution !== 0 && (
          <center>
            <strong>
              Recycling Plastic Contribution: {recyclePlasticContribution} pounds
            </strong>
          </center>
        )}
        <Form.Group as={Row} controlId="paperFormHorizontalCheck">
          <Col sm={{ span: 10, offset: 0 }}>
            <Form.Check
              label="Paper"
              onChange={(e) => setRecyclePaper(!recyclePaper)}
            />
          </Col>
        </Form.Group>
        {recyclePaperContribution !== 0 && (
          <center>
            <strong>
              Recycling Paper Contribution: {recyclePaperContribution} pounds
            </strong>
          </center>
        )}
      </Form.Group>
      {recycleFootprint !== 0 && (
        <Button variant="primary" size="lg" block>
          <h4>
            {" "}
            Annual Recycling Footprint: <strong>{recycleFootprint}</strong>{" "}
            pounds CO<sub>2</sub>
          </h4>
        </Button>
      )}
    </Form>
  );
}

export default MaterialsForm;

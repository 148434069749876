import React, { Component } from "react";
import { Form } from "react-bootstrap";

export default class ToggleDisplayed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHidden: true,
    };
    this.toggleHidden = this.toggleHidden.bind(this);
  }
  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden,
    });
  }
  render() {
    return (
      <div>
        <Form.Check
          type="switch"
          id={this.props.switchId}
          label={this.props.condition}
          onChange={this.toggleHidden}
        />
        {!this.state.isHidden && (
          <div class="col px-md-5">{this.props.children}</div>
        )}
      </div>
    );
  }
}

import React, { useState, useEffect } from "react";
import { Form, Col, Button, ListGroup } from "react-bootstrap";
import {
    diet_heavy_meat_eater,
    diet_medium_meat_eater,
    diet_light_meat_eater,
    diet_pescatarian,
    diet_vegetarian,
    diet_vegan,
} from "../../calculations/calculationConstants";
import {
  calculateFoodFootprint,
} from "../../calculations/calculations";
function FoodForm({ foodFootprint, setFoodFootprint }) {

  const [diet, setDiet] = useState(diet_heavy_meat_eater);
  const [annualSpend, setAnnualSpend] = useState(0);
  const [foodContribution, setFoodContribution] = useState(0);

  useEffect(() => {
    setFoodFootprint(
      foodContribution
    );
  }, [setFoodFootprint, foodContribution]);

  return (
    <Form>
      <br></br>
      <Form.Row>
        <Form.Group as={Col} controlId="dietType">
            <Form.Label>What is most common diet type in your household?</Form.Label>
            <Form.Control
            as="select"
            placeholder={diet}
            onChange={(e) => {
                setDiet(e.target.value);
                setFoodContribution(
                    calculateFoodFootprint(
                        e.target.value,
                        annualSpend
                    )
                );
            }}
            >
            <option>{diet_heavy_meat_eater}</option>
            <option>{diet_medium_meat_eater}</option>
            <option>{diet_light_meat_eater}</option>
            <option>{diet_pescatarian}</option>
            <option>{diet_vegetarian}</option>
            <option>{diet_vegan}</option>
            </Form.Control>
        </Form.Group>
        <Form.Group as={Col} controlId="foodAnnualSpend">
        <Form.Label>Annual dollar amount spent on Food </Form.Label>
        <Form.Control
        type="number"
        min={0}
        placeholder={annualSpend}
        onChange={(e) => {
            setAnnualSpend(e.target.value);
            setFoodContribution( 
                calculateFoodFootprint(
                    diet,
                    e.target.value
                )
            );
        }}
        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
        as={'input'}
        />
      </Form.Group>
    </Form.Row>
    <strong>Categorizing Meat Consumption:</strong>
    <ListGroup>
  <ListGroup.Item>Heavy Meat Eater: 100g or more of meat per day (on average)</ListGroup.Item>
  <ListGroup.Item>Medium Meat Eater: 51g to 99g of meat per day (on average)</ListGroup.Item>
  <ListGroup.Item>Low Meat Eater: 50g or less of meat per day (on average)</ListGroup.Item>
</ListGroup>
    {foodFootprint > 0 && (
        <Button variant="primary" size="lg" block>
          <h4>
            {" "}
            Annual Food Footprint: <strong>
              {foodFootprint}
            </strong>{" "}
            pounds CO
            <sub>2</sub>
          </h4>
        </Button>
      )}
    </Form>
  );
}

export default FoodForm;

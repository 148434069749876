import React, {useState } from "react";
import ApiService from "../../api";
import { Modal, Spinner } from "react-bootstrap";

const CheckoutForm = ({ 
  monthlyOffsetCost,
  homeEnergyFootprint,
  transportationFootprint,
  materialsUseFootprint,
  recycleFootprint,
  foodFootprint,
  servicesFootprint,
  totalFootprint
}) => {

  const [error, setError] = useState(null);
  const [show, setShow] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [modalHeading, setModalHeading] = useState("");
  const [modalText, setModalText] = useState("");
  const handleClose = () => setShow(false);
  // const handleChange = (event) => {
  //     if (event.error) {
  //       setError(event.error.message);
  //     } else {
  //       setError(null);
  //     }
  //   };


  const [email, setEmail] = useState("");
  const handleSubmit = async (event) => {
    event.preventDefault();

    setModalHeading("Processing");
    setModalText("Please wait while we send your request.");
    setWaiting(true);
    setShow(true);

    console.log("");
    var dict = {}
    var breakdown = {}

    breakdown['totalFootprint']  =  totalFootprint;
    breakdown['homeEnergyFootprint'] =  homeEnergyFootprint;
    breakdown['transportationFootprint'] = transportationFootprint;
    breakdown['materialsUseFootprint'] = materialsUseFootprint;
    breakdown['recycleFootprint'] = recycleFootprint;
    breakdown['foodFootprint'] = foodFootprint;
    breakdown['servicesFootprint'] = servicesFootprint;

    dict['email'] = email
    dict['monthly_offset_cost'] = monthlyOffsetCost;
    dict['breakdown'] = JSON.stringify(breakdown)

    ApiService.saveFootprintInfo(dict)
      .then((response) => {
        console.log(response.data);
        setModalHeading("Success");
        setModalText("Thank you for your request!");
        setWaiting(false);
      })
      .catch((error) => {
        console.log(error);
        setModalHeading("Success");
        setModalText("Thank you for your request!");
        setWaiting(false);
      });

      //TODO: revert to signalling an error. We are not going to signal the error right now, because we're 
        // actually saving information to the database successfully, but getting a bad request status code
        // in the response from the backend.  We need to resolve that and then revisit this.
      // .catch((error) => {
      //   console.log(error);
      //   setModalHeading("Error");
      //   setModalText("Sorry, there was an error processing your request.");
      //   setWaiting(false);
      // });
  };

  return (
 
          <div>
                    <form onSubmit={handleSubmit} className="save-footprint-form" style={{ 
  backgroundColor: 'rgba(164, 213, 164, .3)'
}}>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{modalHeading}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {modalText}{" "}
            {waiting && <Spinner animation="border" variant="success" />}
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
        <br/>
        <div>
        <div>
            <h4>
              Based on your annual carbon footprint of <strong>{totalFootprint} lbs.</strong>, we estimate that it would cost{" "}
              <strong>${monthlyOffsetCost}</strong> to offset one month's
              footprint, or <strong>${monthlyOffsetCost * 12}</strong> annually.
            </h4>
          </div>
          <br/>
            <h4>
            <strong>Go Carbon Neutral today by offsetting your full annual carbon footprint and receive a FREE Regenall yard sign!</strong>
            <br/>
            </h4>
            <h5>Enter your email address and click the button below -- then scroll down to buy an offset!</h5>
          </div>
       <div className="form-row">
         <input
           className="form-input"
           id="email"
           name="name"
           type="email"
           placeholder="jenny.rosen@example.com"
           required
           value={email}
           onChange={(event) => {
             setEmail(event.target.value);
           }}
         />
       </div>
          <button type="submit" className="submit-btn">
        I want a yardsign!
      </button>
    </form>
            <br/>
          <script src="https://donorbox.org/widget.js" paypalExpress="false"></script><iframe title="payment" src="https://donorbox.org/embed/lancaster-carbon-footprint-offset-fund" name="donorbox" allowpaymentrequest="allowpaymentrequest" seamless="seamless" frameborder="0" scrolling="no" height="900px" width="100%"></iframe>
        </div>
        
  );
};
export default CheckoutForm;

import React, { useState, useEffect } from "react";
import { Form, Col, Button } from "react-bootstrap";
import { calculateCarFootprint, calculateFlightsFootprint } from "../../calculations/calculations";
import ToggleDisplayed from "../ToggleDisplayed";



function TransportationForm({
  transportationFootprint,
  setTransportationFootprint,
}) {
  const [numCars, setNumCars] = useState(0);
  const [carOneWeeklyMileage, setCarOneWeeklyMileage] = useState(0);
  const [carOneMpg, setCarOneMpg] = useState(25);
  const [carOneFootprint, setCarOneFootprint] = useState(0);
  const [carTwoWeeklyMileage, setCarTwoWeeklyMileage] = useState(0);
  const [carTwoMpg, setCarTwoMpg] = useState(25);
  const [carTwoFootprint, setCarTwoFootprint] = useState(0);
  const [carThreeWeeklyMileage, setCarThreeWeeklyMileage] = useState(0);
  const [carThreeMpg, setCarThreeMpg] = useState(25);
  const [carThreeFootprint, setCarThreeFootprint] = useState(0);
  const [carFourWeeklyMileage, setCarFourWeeklyMileage] = useState(0);
  const [carFourMpg, setCarFourMpg] = useState(25);
  const [carFourFootprint, setCarFourFootprint] = useState(0);
  const [carFiveWeeklyMileage, setCarFiveWeeklyMileage] = useState(0);
  const [carFiveMpg, setCarFiveMpg] = useState(25);
  const [carFiveFootprint, setCarFiveFootprint] = useState(0);

  const [shortFlightCount, setShortFlightCount] = useState(0);
  const [shortFlightContribution, setShortFlightContribution] = useState(0);

  const [mediumFlightCount, setMediumFlightCount] = useState(0);
  const [mediumFlightContribution, setMediumFlightContribution] = useState(0);

  const [longFlightCount, setLongFlightCount] = useState(0);
  const [longFlightContribution, setLongFlightContribution] = useState(0);

  useEffect(() => {
    setCarOneFootprint(calculateCarFootprint(carOneMpg, carOneWeeklyMileage));
  }, [carOneMpg, carOneWeeklyMileage]);
  useEffect(() => {
    setCarTwoFootprint(calculateCarFootprint(carTwoMpg, carTwoWeeklyMileage));
  }, [carTwoMpg, carTwoWeeklyMileage]);
  useEffect(() => {
    setCarThreeFootprint(
      calculateCarFootprint(carThreeMpg, carThreeWeeklyMileage)
    );
  }, [carThreeMpg, carThreeWeeklyMileage]);
  useEffect(() => {
    setCarFourFootprint(
      calculateCarFootprint(carFourMpg, carFourWeeklyMileage)
    );
  }, [carFourMpg, carFourWeeklyMileage]);
  useEffect(() => {
    setCarFiveFootprint(
      calculateCarFootprint(carFiveMpg, carFiveWeeklyMileage)
    );
  }, [carFiveMpg, carFiveWeeklyMileage]);

  useEffect(() => {
    let sumCars = [
      carOneFootprint,
      carTwoFootprint,
      carThreeFootprint,
      carFourFootprint,
      carFiveFootprint,
    ]
      .slice(0, numCars)
      .reduce((a, b) => a + b, 0);
    setTransportationFootprint(sumCars + shortFlightContribution + mediumFlightContribution + longFlightContribution);
  }, [setTransportationFootprint,
    numCars,
    carOneFootprint,
    carTwoFootprint,
    carThreeFootprint,
    carFourFootprint,
    carFiveFootprint,
    shortFlightContribution, 
    mediumFlightContribution, 
    longFlightContribution
  ]);

  return (
    <div>
    <Form>
      <h3>Driving</h3>
      <br></br>

      <Form.Row>
        <Form.Group as={Col} controlId="numCars">
          <Form.Label>How many fossil-fuel consuming cars do you have?</Form.Label>
          <Form.Control
            as="select"
            placeholder={numCars}
            onChange={(e) => {
              setNumCars(e.target.value);
            }}
          >
            <option>0</option>
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
          </Form.Control>
        </Form.Group>
      </Form.Row>
      {numCars >= 1 && (
        <ToggleDisplayed switchId="carOne" condition="See Car #1">
          <Form.Row>
            <Form.Group as={Col} controlId="carOneWeeklyMileage">
              <Form.Label>Car #1 Weekly Mileage</Form.Label>
              <Form.Control
                type="number"
                min={0}
                placeholder={carOneWeeklyMileage}
                onChange={(e) => {
                  setCarOneWeeklyMileage(e.target.value);
                }}
              ></Form.Control>
            </Form.Group>
            <Form.Group as={Col} controlId="carOneMpg">
              <Form.Label>Car #1 MPG</Form.Label>
              <Form.Control
                type="number"
                min={0}
                placeholder={carOneMpg}
                onChange={(e) => {
                  setCarOneMpg(e.target.value);
                }}
              />
            </Form.Group>
          </Form.Row>
          {carOneFootprint > 0 && (
            <center>
              <strong>Car #1 Contribution: {carOneFootprint} pounds</strong>
            </center>
          )}
        </ToggleDisplayed>
      )}
      {numCars >= 2 && (
        <ToggleDisplayed switchId="carTwo" condition="See Car #2">
          <Form.Row>
            <Form.Group as={Col} controlId="carTwoWeeklyMileage">
              <Form.Label>Car #2 Weekly Mileage</Form.Label>
              <Form.Control
                type="number"
                min={0}
                placeholder={carTwoWeeklyMileage}
                onChange={(e) => {
                  setCarTwoWeeklyMileage(e.target.value);
                }}
              ></Form.Control>
            </Form.Group>
            <Form.Group as={Col} controlId="carTwoMpg">
              <Form.Label>Car #2 MPG</Form.Label>
              <Form.Control
                type="number"
                min={0}
                placeholder={carTwoMpg}
                onChange={(e) => {
                  setCarTwoMpg(e.target.value);
                }}
              />
            </Form.Group>
          </Form.Row>
          {carTwoFootprint > 0 && (
            <center>
              <strong>Car #2 Contribution: {carTwoFootprint} pounds</strong>
            </center>
          )}
        </ToggleDisplayed>
      )}
      {numCars >= 3 && (
        <ToggleDisplayed switchId="carThree" condition="See Car #3">
          <Form.Row>
            <Form.Group as={Col} controlId="carThreeWeeklyMileage">
              <Form.Label>Car #3 Weekly Mileage</Form.Label>
              <Form.Control
                type="number"
                min={0}
                placeholder={carThreeWeeklyMileage}
                onChange={(e) => {
                  setCarThreeWeeklyMileage(e.target.value);
                }}
              ></Form.Control>
            </Form.Group>
            <Form.Group as={Col} controlId="carThreeMpg">
              <Form.Label>Car #3 MPG</Form.Label>
              <Form.Control
                type="number"
                min={0}
                placeholder={carThreeMpg}
                onChange={(e) => {
                  setCarThreeMpg(e.target.value);
                }}
              />
            </Form.Group>
          </Form.Row>
          {carThreeFootprint > 0 && (
            <center>
              <strong>Car #3 Contribution: {carThreeFootprint} pounds</strong>
            </center>
          )}
        </ToggleDisplayed>
      )}
      {numCars >= 4 && (
        <ToggleDisplayed switchId="carFour" condition="See Car #4">
          <Form.Row>
            <Form.Group as={Col} controlId="carFourWeeklyMileage">
              <Form.Label>Car #4 Weekly Mileage</Form.Label>
              <Form.Control
                type="number"
                min={0}
                placeholder={carFourWeeklyMileage}
                onChange={(e) => {
                  setCarFourWeeklyMileage(e.target.value);
                }}
              ></Form.Control>
            </Form.Group>
            <Form.Group as={Col} controlId="carFourMpg">
              <Form.Label>Car #4 MPG</Form.Label>
              <Form.Control
                type="number"
                min={0}
                placeholder={carFourMpg}
                onChange={(e) => {
                  setCarFourMpg(e.target.value);
                }}
              />
            </Form.Group>
          </Form.Row>
          {carFourFootprint > 0 && (
            <center>
              <strong>Car #4 Contribution: {carFourFootprint} pounds</strong>
            </center>
          )}
        </ToggleDisplayed>
      )}
      {numCars >= 5 && (
        <ToggleDisplayed switchId="carFive" condition="See Car #5">
          <Form.Row>
            <Form.Group as={Col} controlId="carFiveWeeklyMileage">
              <Form.Label>Car #5 Weekly Mileage</Form.Label>
              <Form.Control
                type="number"
                min={0}
                placeholder={carFiveWeeklyMileage}
                onChange={(e) => {
                  setCarFiveWeeklyMileage(e.target.value);
                }}
              ></Form.Control>
            </Form.Group>
            <Form.Group as={Col} controlId="carFiveMpg">
              <Form.Label>Car #5 MPG</Form.Label>
              <Form.Control
                type="number"
                min={0}
                placeholder={carFiveMpg}
                onChange={(e) => {
                  setCarFiveMpg(e.target.value);
                }}
              />
            </Form.Group>
          </Form.Row>
          {carFiveFootprint > 0 && (
            <center>
              <strong>Car #5 Contribution: {carFiveFootprint} pounds</strong>
            </center>
          )}
        </ToggleDisplayed>
      )}

      <h3>Flights</h3>
      <br></br>
      <Form.Row>
        <Form.Group as={Col} controlId="shortFlightCount">
          <Form.Label>How many <strong>short </strong> flights (900 miles or less) do you take annually?</Form.Label>
          <Form.Control
            type="number"
            min={0}
            placeholder={shortFlightCount}
            onChange={(e) => {
              setShortFlightCount(e.target.value);
              setShortFlightContribution( 
                calculateFlightsFootprint(
                  e.target.value,
                  "short"
                )
              );
            }}
          />
        </Form.Group>
        <Form.Group as={Col} controlId="mediumFlightCount">
          <Form.Label>How many <strong>medium </strong> flights (between 901 and 1499 miles) do you take annually?</Form.Label>
          <Form.Control
            type="number"
            min={0}
            placeholder={mediumFlightCount}
            onChange={(e) => {
              setMediumFlightCount(e.target.value);
              setMediumFlightContribution(
                calculateFlightsFootprint(
                  e.target.value,
                  "medium"
                )
              );
            }}
          />
        </Form.Group>
        <Form.Group as={Col} controlId="longFlightCount">
          <Form.Label>How many <strong>long </strong> flights (1500 miles or more) do you take annually?</Form.Label>
          <Form.Control
            type="number"
            min={0}
            placeholder={longFlightCount}
            onChange={(e) => {
              setLongFlightCount(e.target.value);
              setLongFlightContribution( 
                calculateFlightsFootprint(
                  e.target.value,
                  "long"
                )
              );
            }}
          />
        </Form.Group>
      </Form.Row>
      {transportationFootprint > 0 && (
        <Button variant="primary" size="lg" block>
          <h4>
            {" "}
            Annual Transportation Footprint: <strong>
              {transportationFootprint}
            </strong>{" "}
            pounds CO
            <sub>2</sub>
          </h4>
        </Button>
      )}
    </Form>
    </div>
  );
}

export default TransportationForm;

import axios from "axios";
//export const API_URL = "http://localhost:8000";
export const API_URL = "https://www.regenallfootprintapp.com";
export const api = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-type": "application/json",
  },
});
export default class ApiService {
  static saveFootprintInfo(data = {}) {
    console.log("the data");
    console.log(data);
    return api.post(`${API_URL}/payments/save-footprint-data/`, data);
  }
}

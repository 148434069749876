import React, { useState, useEffect } from "react";
import { Form, Col, Button } from "react-bootstrap";
import {
  calculateMiscFootprint,
} from "../../calculations/calculations";
import {
  misc_category_Hospitality,
  misc_category_Telephone,
  misc_category_Banking,
  misc_category_Entertainment,
} from "../../calculations/calculationConstants";

function ServicesForm({ servicesFootprint, setServicesFootprint }) {
  const [HospitalitySpend, setHospitalitySpend] = useState(0);
  const [TelephoneSpend, setTelephoneSpend] = useState(0);
  const [BankingSpend, setBankingSpend] = useState(0);
  const [EntertainmentSpend, setEntertainmentSpend] = useState(0);

  const [HospitalityContribution, setHospitalityContribution] = useState(0);
  const [TelephoneContribution, setTelephoneContribution] = useState(0);
  const [BankingContribution, setBankingContribution] = useState(0);
  const [EntertainmentContribution, setEntertainmentContribution] = useState(0);
  
  useEffect(() => {
    setServicesFootprint(
      HospitalityContribution +
      TelephoneContribution +
      BankingContribution +
      EntertainmentContribution
    );
  }, [setServicesFootprint, HospitalityContribution, TelephoneContribution, BankingContribution, EntertainmentContribution]);

  return (
    <Form>
      <br></br>
      <Form.Row>
          <Form.Group as={Col} controlId="HospitalitySpend">
          <Form.Label>Annual dollar amount spent on Hospitality Purchases (eating out, hotels, etc.)</Form.Label>
          <Form.Control
            type="number"
            min={0}
            placeholder={HospitalitySpend}
            onChange={(e) => {
              setHospitalitySpend(e.target.value);
              setHospitalityContribution( 
                calculateMiscFootprint(
                  misc_category_Hospitality,
                  e.target.value
                )
              );
            }}
          />
        </Form.Group>
        <Form.Group as={Col} controlId="TelephoneSpend">
          <Form.Label>Annual dollar amount spent on Telephone Purchases (cell phone plan costs)</Form.Label>
          <Form.Control
            type="number"
            min={0}
            placeholder={TelephoneSpend}
            onChange={(e) => {
              setTelephoneSpend(e.target.value);
              setTelephoneContribution( 
                calculateMiscFootprint(
                  misc_category_Telephone,
                  e.target.value
                )
              );
            }}
          />
        </Form.Group>
        <Form.Group as={Col} controlId="BankingSpend">
          <Form.Label>Annual dollar amount spent on Banking Purchases (mortgage and loan interest payments)</Form.Label>
          <Form.Control
            type="number"
            min={0}
            placeholder={BankingSpend}
            onChange={(e) => {
              setBankingSpend(e.target.value);
              setBankingContribution( 
                calculateMiscFootprint(
                  misc_category_Banking,
                  e.target.value
                )
              );
            }}
          />
        </Form.Group>
        <Form.Group as={Col} controlId="EntertainmentSpend">
          <Form.Label>Annual dollar amount spent on Entertainment Purchases (recreational, cultural and sporting activities)</Form.Label>
          <Form.Control
            type="number"
            min={0}
            placeholder={EntertainmentSpend}
            onChange={(e) => {
              setEntertainmentSpend(e.target.value);
              setEntertainmentContribution( 
                calculateMiscFootprint(
                  misc_category_Entertainment,
                  e.target.value
                )
              );
            }}
          />
        </Form.Group>
      </Form.Row>
      {servicesFootprint > 0 && (
        <Button variant="primary" size="lg" block>
          <h4>
            {" "}
            Annual Misc. Footprint: <strong>
              {servicesFootprint}
            </strong>{" "}
            pounds CO
            <sub>2</sub>
          </h4>
        </Button>
      )}
    </Form>
  );
}

export default ServicesForm;
